<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>


<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import { currencyFormat } from '@/libs/utils'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_danapel',
      headers: [
        [{
          label: 'Judul Penelitian',
          dataKey: 'judul_penelitian',
        }, {
          label: 'Ketua TIM',
          dataKey: 'data_master_dosen.full_name'
        }, {
          label: 'Sumber dan Jenis Dana',
          dataKey: 'sumber_dan_jenis_dana',
        }, {
          label: 'TS-2	',
          dataKey: 'ts_2',
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'TS-1	',
          dataKey: 'ts_1',
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'TS	',
          dataKey: 'ts',
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'Rata-Rata',
          dataKey: 'rata_rata',
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
        }]
      ],
      form: {
        inputs: [{
          label: 'Judul Penelitian',
          name: 'judul_penelitian',
          dataKey: 'judul_penelitian',
          type: 'text'
        }, {
          label: 'Ketua TIM',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Sumber dan Jenis Dana',
          name: 'sumber_dan_jenis_dana',
          dataKey: 'sumber_dan_jenis_dana',
          type: 'text'
        }, {
          label: 'TS-2',
          name: 'ts_2',
          dataKey: 'ts_2',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'TS-1',
          name: 'ts_1',
          dataKey: 'ts_1',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'TS',
          name: 'ts',
          dataKey: 'ts',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }]
      },
    }
  },
}
</script>
